<template>
	<div class="bg-white p-4 rounded border shadow-sm h-100">
		<b-overlay :show="loading" variant="light" spinner-type="grow">

			<section class="rounded bg-light-app px-4 pt-4 pb-2 mb-4 position-relative">
				<h1 class="display-4">
					{{ title }}
					<small v-if="description" class="text-muted">{{ description }}</small>
				</h1>
				<b-button variant="primary"
				          @click="add"
				          class="position-absolute"
				          style="right: 1rem; top: 1rem;">
					<b-icon icon="plus" font-scale="2"></b-icon>
				</b-button>
			</section>

			<div class="page-inset shadow">

				<error-alert :error="error" class="m-4"/>

				<splitpanes class="default-theme" :push-other-panes="false">

					<pane size="70" min-size="30" max-size="90">
						<b-navbar type="dark" variant="dark" class="p-1">

							<b-nav-text class="ml-2">{{ bvDataTablePaginationInfo }}</b-nav-text>

							<b-navbar-nav class="ml-auto mr-2">

								<b-nav-item-dropdown right no-caret>
									<template #button-content>
										<b-icon icon="download" aria-hidden="true"></b-icon>
									</template>
									<b-dropdown-item @click="bvDataTableDownloadAll('json')">Download as JSON
									</b-dropdown-item>
									<b-dropdown-item @click="bvDataTableDownloadAll('csv')">Download as CSV
									</b-dropdown-item>
								</b-nav-item-dropdown>

							</b-navbar-nav>

						</b-navbar>

						<div class="d-lg-flex flex-lg-row align-content-center justify-content-between my-3">

							<div>
								<b-input-group class="pr-4 pr-lg-0 ml-lg-2">

									<template #prepend>
										<b-button :disabled="!bvDataTable.textFilter" variant="faded" size="sm"
										          class="shadow-none"
										          @click="bvDataTable.textFilter = null">
											<b-icon v-if="!bvDataTable.textFilter" icon="search"
											        aria-hidden="true"></b-icon>
											<b-icon v-if="bvDataTable.textFilter" icon="x-circle-fill"
											        aria-hidden="true"></b-icon>
										</b-button>
									</template>

									<b-form-input
											v-model="bvDataTable.textFilter"
											size="md"
											placeholder="Search"
									></b-form-input>

								</b-input-group>
							</div>

							<div>
								<b-pagination
										v-model="bvDataTable.currentPage"
										:total-rows="bvDataTable.totalRecords"
										:per-page="bvDataTable.rowsPerPage"
										:aria-controls="bvDataTable.id"
										align="fill"
										style="margin-top: 7px !important;"
										class="m-4 m-lg-0"
								></b-pagination>
							</div>

							<div class="mb-4 mb-lg-0 pr-lg-4 text-center text-lg-right">

								<b-dropdown>
									<template #button-content>
										{{ bvDataTable.rowsPerPage }} rows per page
									</template>
									<b-dropdown-item
											v-for="rpp of bvDataTable.rowsPerPageOptions"
											:key="rpp"
											:active="bvDataTable.rowsPerPage === rpp"
											@click="bvDataTable.rowsPerPage = rpp"
									>{{ rpp }}
									</b-dropdown-item>
								</b-dropdown>

							</div>

						</div>

						<b-table
								striped
								hover
								responsive="true"
								:id="bvDataTable.id"
								:items="bvDataTableFetch"
								:busy.sync="bvDataTable.fetchingData"
								:fields="bvDataTable.headers"
								:primary-key="'id'"
								:per-page="bvDataTable.rowsPerPage"
								:current-page="bvDataTable.currentPage"
								:filter="bvDataTable.textFilter"
								selectable
								selected-variant="primary"
								select-mode="single"
								@row-selected="bvDataTableSelectRow"
						></b-table>

					</pane>

					<pane width="30%">

						<b-alert v-if="!bvDataTable.currentRecord" variant="info" show class="m-2 rounded shadow-sm">
							No {{ resourceName }} selected.
						</b-alert>

						<b-navbar v-if="bvDataTable.currentRecord" type="dark" variant="dark" class="p-2"
						          style="height: 56px">

							<b-nav-text class="ml-2">{{ formHeader }}</b-nav-text>

						</b-navbar>

						<generic-data-form
								v-if="bvDataTable.currentRecord"
								:record="bvDataTable.currentRecord"
								:collection-name="collectionName"
								:additional-fields="additionalFields"
								@reset="bvDataTableRestoreCurrentRecord"
								@changed="bvDataTableUpdateCurrentRecord"
								@deleted="bvDataTableDeleteCurrentRecord"
						/>

					</pane>

				</splitpanes>

			</div>

		</b-overlay>
	</div>
</template>

<script>

import pluralize        from 'pluralize';
import bvDataTableMixin from '@/mixins/bvDataTable.mixin';
import GenericDataForm  from '@/components/GenericDataForm';

export default {

    name      : 'GenericDataEditor',
    mixins    : [ bvDataTableMixin ],
    components: { GenericDataForm },

    props: {

        collectionName  : {
            type    : String,
            required: true
        },
        title           : {
            type    : String,
            required: true
        },
        description     : {
            type: String
        },
        headers         : {
            type   : Array,
            default: () => [
                { key: 'name', label: 'Name', sortable: true }
            ]
        },
        additionalFields: {
            type: Array
        }

    },

    data () {
        return {
            bvDataTable: this.bvDataTableGetDataDefaults (
                {
                    id            : `${ this.collectionName }Table`,
                    collectionName: this.collectionName,
                    resourceName  : this.resourceName,
                    headers       : this.headers
                }
            ),
            loading    : true,
            error      : false
        };
    },

    computed: {

        resourceName () {
            return pluralize.singular ( this.collectionName );
        },

        formHeader () {

            if ( !this.bvDataTable.currentRecord ) {
                return null;
            }

            return ( !this.bvDataTable.currentRecord.id ? 'New ' : 'Editing ' ) + this.resourceName;

        }

    },

    methods: {

        add () {
            this.bvDataTable.currentRecord = {};
        }

    },

    mounted () {
        this.loading = false;
    }

};

</script>

<style scoped lang="scss">

</style>