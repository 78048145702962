<template>
	<b-overlay :show="loading" variant="light" spinner-type="grow">
		<b-form @submit.prevent="saveRecord" class="text-left m-2">

			<b-alert :show="!!error" variant="danger" v-html="error.message"></b-alert>

			<b-card class="mb-2">

				<b-form-group
						label="Name"
						label-for="formName"
						description="Location name"
						class="mb-4 p-4 shadow-sm"
						:invalid-feedback="bvDataForm.validationErrors.name"
						:state="!bvDataForm.validationErrors.name"
				>
					<b-form-input
							id="formName"
							v-model="record.name"
							type="text"
							required
							placeholder="Type a name"
							:disabled="isDisabled"
					></b-form-input>
				</b-form-group>

				<b-form-group
						v-for="field in additionalFields"
						:key="field.key"
						:label="field.label"
						:label-for="field.key"
						:description="field.description"
						class="mb-4 p-4 shadow-sm"
						:invalid-feedback="bvDataForm.validationErrors[field.key]"
						:state="!bvDataForm.validationErrors[field.key]"
				>
					<b-form-input
							:id="field.key"
							v-model="record[field.key]"
							:type="field.type"
							required
							:placeholder="field.placeholder"
							:disabled="isDisabled"
					></b-form-input>
				</b-form-group>

			</b-card>

			<div class="d-flex justify-content-end">
				<b-button v-if="!isNew" type="button" class="text-danger" variant="link"
				          @click="deleteRecord" :disabled="isDisabled">
					Delete
				</b-button>
				<b-button type="button" :disabled="!isNew && !bvDataForm.dirty" class="ml-2 mr-0" variant="secondary"
				          @click="bvDataFormReset">
					{{ isNew ? 'Cancel' : 'Reset' }}
				</b-button>
				<b-button type="submit" :disabled="!bvDataForm.dirty" class="ml-2 mr-0" variant="primary">
					{{ isNew ? 'Create' : 'Save Changes' }}
				</b-button>
			</div>

		</b-form>
	</b-overlay>
</template>

<script>

import bvDataFormMixin                from '@/mixins/bvDataForm.mixin';
import pluralize                      from 'pluralize';
import { cloneDeep, omit, startCase } from 'lodash-es';

export default {

    name      : 'GenericDataForm',
    mixins    : [ bvDataFormMixin ],
    components: {},

    props: {

        record: {
            type: Object
        },

        collectionName: {
            type    : String,
            required: true
        },

        additionalFields: {
            type   : Array,
            default: () => []
        }

    },

    data () {
        return {

            bvDataForm: this.bvDataFormGetDataDefaults (
                {
                    id          : `${ this.collectionName }Form`,
                    resourceName: pluralize.singular ( this.collectionName )
                }
            ),
            loading   : true,
            error     : false

        };
    },

    computed: {

        isNew () {
            return this.record && !this.record.id;
        },

        resourceName () {
            return pluralize.singular ( this.collectionName );
        },

        isDisabled () {

            switch ( this.collectionName ) {

                case 'transaction-types':
                    return this.record.name === 'Club Subscription';

                case 'payment-methods':
                    return this.record.name === 'Standing Order';

            }

            return false;
        }

    },

    methods: {

        async saveRecord () {
            try {

                this.loading = true;
                this.error = false;

                const saveData = cloneDeep ( omit ( this.record, this.$utils.auditFields ) );

                // Convert numbers
                for ( const [ key, value ] of Object.entries ( saveData ) ) {
                    const addnField = this.additionalFields.find ( x => x.key === key );
                    if ( addnField && addnField.type === 'number' ) {
                        saveData[ key ] = parseFloat ( value );
                    }
                }

                // Save changes
                await this.bvDataFormSave ( saveData );
                this.$utils.logger ( 'info', `${ startCase ( this.resourceName ) } saved` );
                this.$bvToast.toast (
                    `Changes to ${ startCase ( this.resourceName ) } were saved successfully.`,
                    {
                        title        : 'Saved',
                        autoHideDelay: 3000,
                        toaster      : 'b-toaster-top-center',
                        solid        : true
                    }
                );

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        },

        async deleteRecord () {
            try {

                this.loading = true;
                this.error = false;

                // Delete record
                const response = await this.bvDataFormDelete ();
                if ( response !== false ) {

                    this.$utils.logger ( 'info', 'Deleted', response.data );
                    this.$bvToast.toast (
                        `${ startCase ( this.resourceName ) } deleted successfully.`,
                        {
                            title        : 'Deleted',
                            autoHideDelay: 3000,
                            toaster      : 'b-toaster-top-center',
                            solid        : true
                        }
                    );

                }

                this.loading = false;
                return true;

            } catch ( e ) {
                this.error = {
                    message: 'The form has errors, please review and try again.',
                    errors : e.errors
                };
                this.bvDataForm.validationErrors = this.$utils.parseFormValidationErrors ( this.error.errors );
                this.loading = false;
                return false;
            }
        }

    },

    mounted () {
        this.loading = false;
    }

};

</script>

<style scoped lang="scss">

</style>